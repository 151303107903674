@import '../../styles/variables.scss';

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

// .HydroSDK-toggleButton {
//     outline: none;
//     border: none;
//     background-color: transparent !important;
//     border-color: transparent !important;
//     border-style: none;
//     border-radius: 0px;
//     font-size: 16px;
//     margin-left: 8px;
//     padding: 0px !important;
//     color: black !important;
//     box-shadow: none;
//     &:hover,
//     &:focus,
//     &:active,
//     &:visited {
//         box-shadow: none;
//         border: transparent;
//         outline: none !important;
//     }
// }

// .HydroSDK-toggleButtonDark {
//     outline: none;
//     border: none;
//     background-color: transparent !important;
//     border-color: transparent !important;
//     border-style: none;
//     border-radius: 0px;
//     font-size: 16px;
//     margin-left: 8px;
//     padding: 0px !important;
//     color: #8d94af !important;
//     box-shadow: none;
//     &:hover,
//     &:focus,
//     &:active,
//     &:visited {
//         box-shadow: none;
//         border: transparent;
//         outline: none !important;
//     }
// }

.connect {
    background-color: transparent;
    border: none;
    color: black;
    border-radius: 2px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        box-shadow: none;
        border: transparent;
        outline: none !important;
    }
}

@media screen and (max-width: 768px) {
    .collapse {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #535ec3;
        z-index: 1;
        .item {
            margin: 4px 0;
            border: none;
        }
        .HydroSDK-toggleButton {
            border: none;
        }
    }
}

@media screen and (min-width: 767px) {
    .dropdown-toggle::after {
        margin-left: 12px;
    }
    .item {
        margin-right: 12px;
    }
    .collapse-toggle {
        display: none;
    }
    .collapse,
    .collapse:not(.show) {
        display: flex;
    }
}
